import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import aiProfileImage from './IMG_0225.PNG'; // Replace with your actual image path

function AIProfileFeature() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <AIProfileSection>
      <ContentWrapper
        ref={ref}
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <TextContent>
          <Label as={motion.span} variants={itemVariants}>NEW FEATURE</Label>
          <Title as={motion.h2} variants={itemVariants}>Stork AI Profiles</Title>
          <Subtitle as={motion.p} variants={itemVariants}>Smart insights for your child's growth, play, and nutrition.</Subtitle>
          <Description as={motion.p} variants={itemVariants}>
            Parenting made simpler! With Stork AI Profiles, you get personalized, AI-powered recommendations tailored to your child's unique needs. Monitor growth trends, explore age-appropriate activities, and achieve daily goals for play and nutrition — all in one place.
          </Description>
          <Button as={motion.button} variants={itemVariants} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            Learn More About AI Profiles
          </Button>
        </TextContent>
        <ImageContent as={motion.div} variants={itemVariants}>
          <FeatureImage
            src={aiProfileImage}
            alt="Stork AI Profile Feature"
            as={motion.img}
            whileHover={{ scale: 1.05, rotate: 0 }}
            transition={{ type: 'spring', stiffness: 300 }}
          />
        </ImageContent>
      </ContentWrapper>
      <BackgroundAnimation />
    </AIProfileSection>
  );
}

// Styled Components
const AIProfileSection = styled.section`
  background: linear-gradient(135deg, #fbf8f3 0%, #f5e5d5 100%); /* Neutral Warmth */
  padding: 100px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  gap: 40px;
  flex-wrap: wrap;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9); /* Glassmorphism Effect */
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
`;

const TextContent = styled(motion.div)`
  max-width: 600px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Label = styled(motion.span)`
  display: inline-block;
  background: #d64912; /* Pop of Orange */
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 20px;
  margin-bottom: 15px;
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #9dC4B1; /* Soaring Oyan */
  margin-bottom: 30px;
`;

const Description = styled(motion.p)`
  font-size: 1rem;
  color: #666666;
  line-height: 1.8;
  margin-bottom: 30px;
`;

const Button = styled(motion.button)`
  background: #d64912; /* Pop of Orange */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: #b53d0e; /* Darker Pop of Orange */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContent = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%; /* Scaled to 40% */
`;

const FeatureImage = styled(motion.img)`
  width: 40%;
  height: auto;
  border-radius: 20px;
  transform: rotate(-5deg);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: rotate(0deg) scale(1.05);
  }
`;

const BackgroundAnimation = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, transparent 20%, #f2f7fb 20%, #f2f7fb 80%, transparent 80%, transparent),
              radial-gradient(circle, transparent 20%, #f2f7fb 20%, #f2f7fb 80%, transparent 80%, transparent) 50px 50px,
              linear-gradient(#e5e5f7 2px, transparent 2px) 0 -1px,
              linear-gradient(90deg, #e5e5f7 2px, #f2f7fb 2px) -1px 0;
  background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
  animation: moveBackground 15s linear infinite;
  opacity: 0.5;
  z-index: 0;

  @keyframes moveBackground {
    0% {
      background-position: 0 0, 50px 50px, 0 0, 0 0;
    }
    100% {
      background-position: 100px 100px, 150px 150px, 50px 50px, 50px 50px;
    }
  }
`;

export default AIProfileFeature;