import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';

function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: 'What is Stork?', answer: 'Stork is a subscription service that provides curated boxes of baby essentials delivered straight to your doorstep.' },
    { question: 'How does the Stork subscription work?', answer: 'Simply choose a subscription plan, and we will deliver a box of baby essentials tailored to your needs every month.' },
    { question: 'What is the difference between Stork Box and Stork Box Lite?', answer: 'Stork Box offers a full range of premium products, while Stork Box Lite focuses on cost-effective essentials.' },
    { question: 'Can I gift a Stork subscription?', answer: 'Yes! Stork allows you to send gift subscriptions or one-time gift boxes to friends and family.' },
    { question: 'What is included in a Stork Box?', answer: 'Each Stork Box includes diapers, wipes, premium baby products, and additional curated items tailored to your needs.' },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQWrapper>
      <Title
        as={motion.h2}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Frequently Asked Questions
      </Title>
      <FAQContainer>
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <FAQQuestion onClick={() => toggleFAQ(index)}>
              {faq.question}
              <IconWrapper
                as={motion.div}
                animate={{ rotate: activeIndex === index ? 180 : 0 }}
              >
                <FaChevronDown />
              </IconWrapper>
            </FAQQuestion>
            <AnimatePresence>
              {activeIndex === index && (
                <FAQAnswer
                  as={motion.div}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {faq.answer}
                </FAQAnswer>
              )}
            </AnimatePresence>
          </FAQItem>
        ))}
      </FAQContainer>
    </FAQWrapper>
  );
}

// Styled Components
const FAQWrapper = styled.section`
  padding: 80px 20px;
  background: linear-gradient(135deg, #fbf8f3 0%, #f5e5d5 100%); /* Neutral Warmth */
  text-align: center;
`;

const Title = styled(motion.h2)`
  font-size: 2.8rem;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 50px;
  font-weight: 700;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9); /* Glassmorphism Effect */
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

const FAQItem = styled(motion.div)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-bottom: none;
  }
`;

const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333333; /* Very Dark Grey */
  padding: 25px 30px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(193, 221, 209, 0.2); /* Calm Oyan with Opacity */
    transform: translateY(-2px);
  }
`;

const IconWrapper = styled(motion.div)`
  color: #d64912; /* Pop of Orange */
  transition: transform 0.3s ease;
`;

const FAQAnswer = styled(motion.div)`
  font-size: 1rem;
  color: #666666;
  padding: 0 30px 20px;
  line-height: 1.6;
  background: rgba(193, 221, 209, 0.1); /* Calm Oyan with Opacity */
`;

export default FAQSection;