import React, { useState } from 'react';
import styled from 'styled-components';
import storkBoxLiteImage from './storkboxlite.jpg'; // Add path to your Stork Box Lite image
import storkBoxImage from './storkbox.jpg'; // Add path to your Stork Box image

function FeatureShowcase() {
  const [isYearly, setIsYearly] = useState(false);

  const togglePricing = () => {
    setIsYearly((prev) => !prev);
  };

  const boxes = [
    {
      id: 'storkBoxLite',
      name: 'Stork Box Lite',
      monthlyPrice: '$59/m',
      yearlyPrice: '$49/m (Billed Yearly)',
      image: storkBoxLiteImage,
      description: [
        'Month supply of Diapers',
        'Month supply of Wipes and Rash Cream',
        '1 New Sample Product',
        '2 Premium Baby Bottles',
      ],
    },
    {
      id: 'storkBox',
      name: 'Stork Box',
      monthlyPrice: '$99/m',
      yearlyPrice: '$89/m (Billed Yearly)',
      image: storkBoxImage,
      description: [
        'Month supply of Diapers',
        'Month supply of Wipes and Rash Cream',
        '2 New Sample Products in every box',
        '2 Premium Baby Bottles',
        '2 pairs of cozy socks for tiny toes',
        '2 engaging baby toys for fun and learning',
      ],
      discountTag: '10% off your first month!',
    },
  ];

  return (
    <ShowcaseSection>
      <Title>Stork Box Lite and Stork Box</Title>
      <ToggleContainer>
        <ToggleLabel>Monthly</ToggleLabel>
        <ToggleSwitch>
          <ToggleInput
            type="checkbox"
            checked={isYearly}
            onChange={togglePricing}
          />
          <Slider />
        </ToggleSwitch>
        <ToggleLabel>Yearly</ToggleLabel>
      </ToggleContainer>
      <BoxGrid>
        {boxes.map((box) => (
          <BoxCard key={box.id}>
            <BoxImage src={box.image} alt={box.name} />
            <BoxHeader>
              <BoxPrice>{isYearly ? box.yearlyPrice : box.monthlyPrice}</BoxPrice>
              <SubText>{isYearly ? 'Billed Yearly' : 'Billed Monthly'}</SubText>
            </BoxHeader>
            <BoxBody>
              <BoxTitle>{box.name}</BoxTitle>
              <FeaturesList>
                {box.description.map((item, index) => (
                  <FeatureItem key={index}>{item}</FeatureItem>
                ))}
              </FeaturesList>
              <PurchaseButton>Purchase On Stork App</PurchaseButton>
              {box.discountTag && <DiscountTag>{box.discountTag}</DiscountTag>}
            </BoxBody>
          </BoxCard>
        ))}
      </BoxGrid>
    </ShowcaseSection>
  );
}

const ShowcaseSection = styled.section`
  padding: 60px 20px;
  text-align: center;
  background: #fbf8f3;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const ToggleLabel = styled.span`
  font-size: 1rem;
  color: #555;
  margin: 0 10px;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #4caf50;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BoxCard = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const BoxImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 20px;
`;

const BoxHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const BoxPrice = styled.h3`
  font-size: 2rem;
  color: #dd4912;
  margin: 0;
`;

const SubText = styled.p`
  font-size: 0.9rem;
  color: #777;
`;

const BoxBody = styled.div`
  padding: 0 10px;
`;

const BoxTitle = styled.h4`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;

  &::before {
    content: '✔';
    color: #4caf50;
    font-weight: bold;
    margin-right: 8px;
  }
`;

const PurchaseButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  background: #dd4912;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #b93e0d;
  }
`;

const DiscountTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff9800;
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 5px;
  font-weight: bold;
`;

export default FeatureShowcase;
