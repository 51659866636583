import React from "react";
import styled, { keyframes } from "styled-components";
import { FaLink, FaShareAlt, FaWallet, FaGift } from "react-icons/fa";

function StorkHero() {
  const stepsData = [
    {
      title: "Create a Gift Link",
      description: "Download and create an account on the Stork App",
      icon: <FaLink />,
    },
    {
      title: "Share with Loved Ones",
      description: "Send the gift link to family and friends or post on social media",
      icon: <FaShareAlt />,
    },
    {
      title: "Receive Contributions",
      description: "Track contributions and gifts in your Stork Wallet",
      icon: <FaWallet />,
    },
    {
      title: "Celebrate Together",
      description: "Use contributions for your child's needs and milestones",
      icon: <FaGift />,
    },
  ];

  return (
    <HeroSection>
      <ContentWrapper>
        <HeroContent>
          <MainHeading>
            Simplify Parenting with <HighlightText>Stork</HighlightText>
          </MainHeading>
          <SubHeading>
            Make gifting and support easy for your family and friends.
          </SubHeading>
          <CtaButton>Get Started Now</CtaButton>
        </HeroContent>
        <StepsSection>
          {stepsData.map((step, index) => (
            <StepCard key={index}>
              <StepNumber>{index + 1}</StepNumber>
              <Icon>{step.icon}</Icon>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepCard>
          ))}
        </StepsSection>
      </ContentWrapper>
    </HeroSection>
  );
}

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #fdf7f2 0%, #f5e5d5 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 80px 20px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

const HeroContent = styled.div`
  text-align: center;
  animation: ${fadeIn} 0.8s ease-out;
`;

const MainHeading = styled.h1`
  font-size: 3.5rem;
  color: #4b4a4a; /* Updated to brand neutral color */
  margin-bottom: 20px;
  font-weight: 800;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HighlightText = styled.span`
  color: #f16c4d; /* Updated to Stork brand accent */
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 10px;
    background-color: rgba(241, 108, 77, 0.2);
    z-index: -1;
  }
`;

const SubHeading = styled.p`
  font-size: 1.25rem;
  color: #4b4a4a; /* Updated to brand neutral color */
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StepsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  width: 100%;
`;

const StepCard = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 30px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #f16c4d, #faa992); /* Stork brand gradient */
  }
`;

const StepNumber = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #f16c4d; /* Stork brand color */
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: #f16c4d; /* Stork brand color */
  margin-bottom: 20px;
  animation: ${float} 3s ease-in-out infinite;
`;

const StepTitle = styled.h3`
  font-size: 1.3rem;
  color: #4b4a4a; /* Updated to brand neutral color */
  margin-bottom: 15px;
  font-weight: 600;
`;

const StepDescription = styled.p`
  font-size: 0.95rem;
  color: #4b4a4a; /* Updated to brand neutral color */
  line-height: 1.6;
`;

const CtaButton = styled.button`
  background-color: #f16c4d; /* Stork brand color */
  color: #ffffff;
  border: none;
  padding: 15px 40px;
  border-radius: 9999px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #d4553a; /* Darker Stork brand color */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export default StorkHero;
