import React, { useState, useEffect } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { FaGift, FaHeart, FaCreditCard, FaCalendarAlt } from 'react-icons/fa';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
  
  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #fdf7f2 0%, #f5e5d5 100%);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

function StorkGifting() {
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [recipientStripeAccountId, setRecipientStripeAccountId] = useState('');
  const [supportAmount, setSupportAmount] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [activeTab, setActiveTab] = useState('Registry');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const giftId = urlParams.get('giftId');
    if (giftId) {
      fetchGiftDetails(giftId);
    } else {
      setError('No giftId found in URL.');
      setLoading(false);
    }
  }, []);

  const fetchGiftDetails = async (giftId) => {
    try {
      setLoading(true);
      const usersColl = collection(db, 'users');
      const giftQuery = query(usersColl, where('giftId', '==', giftId));
      const giftSnap = await getDocs(giftQuery);
  
      if (!giftSnap.empty) {
        const userData = giftSnap.docs[0].data();
  
        // Set recipient details
        setRecipientName(userData.full_name || '');
        setRecipientEmail(userData.email || '');
  
        // Use recipientStripeAccountId as recipientId
        if (userData.recipientStripeAccountId) {
          setRecipientStripeAccountId(userData.recipientStripeAccountId); // Update this with recipientStripeAccountId
        } else {
          console.error('Missing recipientStripeAccountId in user data.');
          setError('Recipient information is incomplete. Please try again later.');
        }
  
        // Check if recipient is set up with Stripe Connect
        if (userData.recipientStripeAccountId === 'not connected') {
          setError('The recipient has not completed their Stripe setup. Please check back later.');
          setRecipientStripeAccountId(null); // Prevent any further payment action
        } else {
          setRecipientStripeAccountId(userData.recipientStripeAccountId); // Set valid Stripe account ID
        }
      } else {
        setError('No matching user for this gift link.');
      }
    } catch (err) {
      console.error('Error fetching gift details:', err);
      setError('Error fetching gift details. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const createCheckoutSession = async (paymentType) => {
    try {
      setLoading(true);
      if (!recipientStripeAccountId || recipientStripeAccountId === 'not connected') {
        throw new Error('The recipient is not set up for payments. Please check back later.');
      }
  
      if (paymentType === 'one_time' && (!supportAmount || isNaN(supportAmount) || supportAmount <= 0)) {
        throw new Error('Please enter a valid support amount.');
      }
      if (paymentType === 'monthly' && !subscriptionTier) {
        throw new Error('Please select a subscription tier.');
      }
  
      const requestBody = {
        paymentType,
        recipientStripeAccountId,
        recipientName,
        recipientEmail, // Include recipient email
      };
  
      if (paymentType === 'one_time') {
        requestBody.amount = Number(supportAmount) * 100; // Convert dollars to cents
      }
      if (paymentType === 'monthly') {
        requestBody.subscriptionTier = subscriptionTier;
      }
  
      console.log('Request payload:', requestBody);
  
      const response = await fetch('https://storkcares.com/prod/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
      if (response.ok && data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        throw new Error(data.error || 'Failed to create checkout session.');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError(error.message || 'Failed to initiate payment. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <GlobalStyle />
      <MainWrapper>
        <GlassmorphicCard>
          <HeroContent>
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {!loading && !error && (
              <>
                <Title>
                  Send a Gift to <Highlight>{recipientName}</Highlight>
                </Title>
                <Subtitle>{recipientEmail}</Subtitle>

                <TabContainer>
                  <TabButton
                    onClick={() => setActiveTab('Registry')}
                    active={activeTab === 'Registry'}
                  >
                    <FaGift /> Registry
                  </TabButton>
                  <TabButton
                    onClick={() => setActiveTab('Payment')}
                    active={activeTab === 'Payment'}
                  >
                    <FaHeart /> Support
                  </TabButton>
                </TabContainer>
              </>
            )}
          </HeroContent>

          {!loading && !error && activeTab === 'Payment' && (
            <PaymentContainer>
              <PaymentTitle>Support {recipientName}</PaymentTitle>

              <InputWrapper>
                <StyledLabel htmlFor="amountInput">
                  Enter Support Amount (USD):
                </StyledLabel>
                <StyledInput
                  type="number"
                  id="amountInput"
                  placeholder="e.g., 15"
                  onChange={(e) => setSupportAmount(e.target.value)}
                />
              </InputWrapper>
              <GiftBoxButton onClick={() => createCheckoutSession('one_time')}>
                Make One-Time Payment
              </GiftBoxButton>

              <SubscriptionSection>
                <StyledLabel>Select Monthly Support Tier:</StyledLabel>
                <TierOptions>
                  <TierButton
                    active={subscriptionTier === '50'}
                    onClick={() => setSubscriptionTier('50')}
                  >
                    $50 / month
                  </TierButton>
                  <TierButton
                    active={subscriptionTier === '20'}
                    onClick={() => setSubscriptionTier('20')}
                  >
                    $20 / month
                  </TierButton>
                  <TierButton
                    active={subscriptionTier === '10'}
                    onClick={() => setSubscriptionTier('10')}
                  >
                    $10 / month
                  </TierButton>
                </TierOptions>
                <GiftBoxButton onClick={() => createCheckoutSession('monthly')}>
                  Set Up Monthly Support
                </GiftBoxButton>
              </SubscriptionSection>
            </PaymentContainer>
          )}
        </GlassmorphicCard>
      </MainWrapper>
    </>
  );
}

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #fdf7f2 0%, #f5e5d5 100%);
`;

const GlassmorphicCard = styled.div`
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 3rem;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 90%;
  max-width: 800px;
  animation: ${fadeIn} 0.6s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(31, 38, 135, 0.5);
  }
`;

const HeroContent = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #4b4a4a;
  margin-bottom: 0.5rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Highlight = styled.span`
  color: #f16c4d;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

const Subtitle = styled.h3`
  font-size: 1.2rem;
  color: #4b4a4a;
  margin-bottom: 2rem;
  font-weight: 400;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? '#f16c4d' : 'transparent')};
  color: ${(props) => (props.active ? '#ffffff' : '#4b4a4a')};
  border: 2px solid #f16c4d;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: ${(props) =>
    props.active ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'};

  &:hover {
    background: #f16c4d;
    color: #ffffff;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const PaymentContainer = styled.div`
  margin-top: 2rem;
  animation: ${fadeIn} 0.6s ease-out;
`;

const PaymentTitle = styled.h2`
  font-size: 2rem;
  color: #4b4a4a;
  margin-bottom: 1.5rem;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

const InputWrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #4b4a4a;
  font-size: 1rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #f16c4d;
    box-shadow: 0 0 0 3px rgba(241, 108, 77, 0.2);
  }
`;

const SubscriptionSection = styled.div`
  margin-top: 2rem;
`;

const TierOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
`;

const TierButton = styled.button`
  background: ${(props) => (props.active ? '#f16c4d' : '#e2e8f0')};
  color: ${(props) => (props.active ? '#ffffff' : '#4b4a4a')};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: ${(props) =>
    props.active ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'};

  &:hover {
    background: ${(props) => (props.active ? '#d4553a' : '#cbd5e0')};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const GiftBoxButton = styled.button`
  background: #f16c4d;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #d4553a;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ErrorMessage = styled.p`
  color: #e53e3e;
  background: #fed7d7;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
  font-size: 1rem;
  animation: ${fadeIn} 0.6s ease-out;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(241, 108, 77, 0.3);
  border-top: 4px solid #f16c4d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 2rem auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default StorkGifting;
