import React from 'react';
import styled from 'styled-components';
import logo from './storknobackg.png';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';

function Footer() {
  return (
    <FooterSection>
      <FooterContent>
        <LogoAndSocial>
          <Logo src={logo} alt="Stork Logo" />
          <SocialIcons>
            <SocialIcon href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialIcon>
          </SocialIcons>
        </LogoAndSocial>
        <FooterLinksContainer>
          <FooterLinkColumn>
            <FooterLinkTitle>Company</FooterLinkTitle>
            <FooterLink href="#about">About Us</FooterLink>
            <FooterLink href="#careers">Careers</FooterLink>
            <FooterLink href="#press">Press</FooterLink>
          </FooterLinkColumn>
          <FooterLinkColumn>
            <FooterLinkTitle>Product</FooterLinkTitle>
            <FooterLink href="#features">Features</FooterLink>
            <FooterLink href="#how-it-works">How It Works</FooterLink>
            <FooterLink href="#pricing">Pricing</FooterLink>
          </FooterLinkColumn>
          <FooterLinkColumn>
            <FooterLinkTitle>Resources</FooterLinkTitle>
            <FooterLink href="#blog">Blog</FooterLink>
            <FooterLink href="#help-center">Help Center</FooterLink>
            <FooterLink href="#contact">Contact Us</FooterLink>
          </FooterLinkColumn>
        </FooterLinksContainer>
      </FooterContent>
      <FooterBottom>
        <Copyright>&copy; {new Date().getFullYear()} Stork. All rights reserved.</Copyright>
        <LegalLinks>
          <LegalLink href="#privacy">Privacy Policy</LegalLink>
          <LegalLink href="#terms">Terms of Service</LegalLink>
        </LegalLinks>
      </FooterBottom>
    </FooterSection>
  );
}

const FooterSection = styled.footer`
  background: linear-gradient(135deg, #2e6a8e, #447c9d);
  color: white;
  padding: 60px 20px 30px;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
`;

const LogoAndSocial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const Logo = styled.img`
  height: 60px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #ff6f3c;
    transform: translateY(-3px);
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
`;

const FooterLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FooterLinkTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FooterLink = styled.a`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6f3c;
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 40px auto 0;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: #e0e0e0;
`;

const LegalLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const LegalLink = styled.a`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6f3c;
  }
`;

export default Footer;
