import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import babyEssentialsImage from './baby-essentials.jpg'; // Replace with your image

function StorkBoxSection() {
  return (
    <StorkBoxWrapper>
      <SectionHeader>
        <SectionTitle>HOW IT WORKS</SectionTitle>
        <SectionSubtitle>
          We believe baby happiness is serious business. Here's how we deliver it right to your door, every month.
        </SectionSubtitle>
      </SectionHeader>
      <ContentWrapper>
        <TextContent>
          <StepsContainer>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <StepTitle>Tell us about your baby</StepTitle>
                <StepDescription>
                  Share your baby's age, preferences, and needs so we can tailor the perfect box for them.
                </StepDescription>
              </StepContent>
            </Step>
            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <StepTitle>Pick your plan</StepTitle>
                <StepDescription>
                  Choose from 1, 6, or 12-month plans. Cancel anytime. Free shipping included.
                </StepDescription>
              </StepContent>
            </Step>
            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <StepTitle>Unbox joy every month</StepTitle>
                <StepDescription>
                  Discover new baby essentials, from diapers and wipes to toys and snacks, tailored to your baby's needs.
                </StepDescription>
              </StepContent>
            </Step>
          </StepsContainer>
          <CtaButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            Get Started
          </CtaButton>
        </TextContent>
        <ImageContent>
          <FeatureImage
            src={babyEssentialsImage}
            alt="StorkBox Baby Essentials"
            as={motion.img}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          />
        </ImageContent>
      </ContentWrapper>
    </StorkBoxWrapper>
  );
}

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Styled Components
const StorkBoxWrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(135deg, #fdf7f2 0%, #f5e5d5 100%); /* Stork brand background */
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #4b4a4a; /* Stork brand neutral color */
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1.2rem;
  color: #4b4a4a; /* Stork brand neutral color */
  max-width: 600px;
  line-height: 1.6;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ImageContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 40px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const StepNumber = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: #f16c4d; /* Stork brand accent color */
  line-height: 1;
`;

const StepContent = styled.div`
  flex: 1;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: #4b4a4a; /* Stork brand neutral color */
  margin-bottom: 10px;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #666666;
  line-height: 1.6;
`;

const CtaButton = styled(motion.button)`
  background: #f16c4d; /* Stork brand accent color */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: #d4553a; /* Darker Stork brand accent */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const FeatureImage = styled(motion.img)`
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export default StorkBoxSection;
