import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import loginImage from './IMG_0220.PNG'; // Replace with your main image path
import profileImage from './IMG_0223.PNG'; // Replace with your secondary image path

function CommunitySection() {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <TextContainer>
          <Title>Join Our Community of Happy Parents</Title>
          <Description>
            Discover how Stork simplifies parenthood by delivering curated baby essentials to your doorstep. Whether you're a new parent or looking for the perfect gift, we've got you covered.
          </Description>
          <ActionButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            Download Today &rarr;
          </ActionButton>
          <SubText>Stress-free parenthood starts here</SubText>
        </TextContainer>
        <ImageWrapper>
          <MainImageContainer>
            <StyledImage
              src={loginImage}
              alt="Login Preview"
              as={motion.img}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
            <SecondaryImageContainer>
              <StyledSecondaryImage
                src={profileImage}
                alt="Profile Preview"
                as={motion.img}
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              />
            </SecondaryImageContainer>
          </MainImageContainer>
        </ImageWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
}

// Styled Components
const SectionWrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(135deg, #fbf8f3 0%, #f5e5d5 100%); /* Neutral Warmth */
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #333333; /* Soaring Oyan */
  line-height: 1.8;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ActionButton = styled(motion.button)`
  background: #d64912; /* Pop of Orange */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: #b53d0e; /* Darker Pop of Orange */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SubText = styled.p`
  font-size: 1rem;
  color: #666666;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImageContainer = styled.div`
  position: relative;
  max-width: 400px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledImage = styled(motion.img)`
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
`;

const SecondaryImageContainer = styled.div`
  position: absolute;
  bottom: -30px;
  left: -30px;
  max-width: 120px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (max-width: 768px) {
    bottom: -10px;
    left: -10px;
    max-width: 80px;
  }
`;

const StyledSecondaryImage = styled(motion.img)`
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

export default CommunitySection;