import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function ContactSection() {
  const handleEmailClick = () => {
    const mailtoLink = `mailto:amaan@storkcares.com`;
    window.location.href = mailtoLink;
  };

  return (
    <ContactWrapper>
      <TextContainer>
        <Title>Let Stork Simplify Your Life</Title>
        <Subtitle>
          Have questions? Reach out to us and see how Stork can make parenthood stress-free.
        </Subtitle>
        <ActionButton
          onClick={handleEmailClick}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Email Us
        </ActionButton>
      </TextContainer>
    </ContactWrapper>
  );
}

// Styled Components
const ContactWrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(135deg, #fbf8f3 0%, #f5e5d5 100%); /* Neutral Warmth */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const TextContainer = styled.div`
  max-width: 600px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #333333; /* Soaring Oyan */
  line-height: 1.6;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ActionButton = styled(motion.button)`
  background: #d64912; /* Pop of Orange */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: #b53d0e; /* Darker Pop of Orange */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export default ContactSection;