// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AlzaSyCMI95Pa0YCeAXqh0gj_0UJQNXf0XGVIXQ", // Your Web API Key
  authDomain: "stork-supplies-ijp4c5.firebaseapp.com", // Typically "your-project-id.firebaseapp.com"
  projectId: "stork-supplies-ijp4c5", // Your Project ID
  storageBucket: "stork-supplies-ijp4c5.appspot.com", // Usually "your-project-id.appspot.com"
  messagingSenderId: "31811548067", // Your project number
  appId: "Your App ID (if provided in Firebase settings)" // Replace with actual App ID if available
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
