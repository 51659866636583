import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import logo from './storklogo.png';

function MissionSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <MissionWrapper>
      <ContentContainer
        ref={ref}
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <LeftColumn as={motion.div} variants={itemVariants}>
          <Label>Our Mission</Label>
          <MissionText>
            At <StorkText>Stork</StorkText>, we're dedicated to simplifying parenthood by delivering essential supplies with unmatched convenience and reliability. Our goal is to help new parents focus on what matters most—their baby's growth and happiness.
          </MissionText>
          <Author>- Amaan Moledina, Founder of Stork</Author>
        </LeftColumn>
        <RightColumn as={motion.div} variants={itemVariants}>
          <ImageWrapper>
            <MissionImage
              src={logo}
              alt="Our Mission"
              as={motion.img}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
          </ImageWrapper>
        </RightColumn>
      </ContentContainer>
    </MissionWrapper>
  );
}

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const MissionWrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ContentContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: 1200px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 60px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

  @media (min-width: 968px) {
    flex-direction: row;
  }
`;

const LeftColumn = styled(motion.div)`
  flex: 1;
  padding: 20px;
`;

const Label = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background: #dd4912;
  padding: 10px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px rgba(221, 73, 18, 0.3);
`;

const MissionText = styled.p`
  font-size: 1.5rem;
  line-height: 1.8;
  color: #333333;
  margin-bottom: 30px;
`;

const StorkText = styled.strong`
  color: #dd4912;
  font-weight: bold;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #dd4912;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const Author = styled.p`
  font-size: 1.1rem;
  color: #666666;
  margin-top: 30px;
  font-style: italic;
`;

const RightColumn = styled(motion.div)`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const MissionImage = styled(motion.img)`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export default MissionSection;
