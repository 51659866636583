import React, { useEffect, useState } from 'react';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase"; // Your Firestore config
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function SuccessPage() {
  const [message, setMessage] = useState('');
  const [giftDetails, setGiftDetails] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const isTesting = process.env.NODE_ENV === 'development';
  const dummyData = {
    giftId: "testGift123",
    productId: "JOYFUL",
    productName: "Joyful Beginnings",
    productPrice: "$30.00",
    recipient: "John Doe",
    status: "PAID",
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const giftId = params.get('giftId') || (isTesting ? dummyData.giftId : null);
    const productId = params.get('productId') || (isTesting ? dummyData.productId : null);

    if (!giftId || !productId) {
      setMessage("Missing gift or product info.");
      return;
    }

    // Record purchase in Firestore and simulate fetching product details
    recordGiftPurchase(giftId, productId);
    setGiftDetails(isTesting ? dummyData : null); // Show dummy data in dev mode
  }, [location]);

  const recordGiftPurchase = async (giftId, productId) => {
    try {
      if (!isTesting) {
        await addDoc(collection(db, "orders"), {
          giftId,
          productId,
          status: "PAID",
          createdAt: serverTimestamp(),
        });
      }
      setMessage("Your order has been recorded successfully!");
    } catch (err) {
      console.error("Error recording purchase:", err);
      setMessage("Error recording purchase. Please contact support.");
    }
  };

  return (
    <SuccessContainer>
      <Card>
        <SuccessIcon>✔️</SuccessIcon>
        <h1>Payment Successful</h1>
        <p>{message}</p>

        {giftDetails && (
          <GiftDetails>
            <h3>Order Summary</h3>
            <DetailItem>
              <span>Gift:</span>
              <strong>{giftDetails.productName}</strong>
            </DetailItem>
            <DetailItem>
              <span>Price:</span>
              <strong>{giftDetails.productPrice}</strong>
            </DetailItem>
            <DetailItem>
              <span>Recipient:</span>
              <strong>{giftDetails.recipient}</strong>
            </DetailItem>
            <DetailItem>
              <span>Status:</span>
              <StatusBadge>{giftDetails.status}</StatusBadge>
            </DetailItem>
          </GiftDetails>
        )}

        <ButtonGroup>
          <ActionButton onClick={() => navigate('/')}>Return Home</ActionButton>
          <SecondaryButton onClick={() => window.location.reload()}>
            View Details Again
          </SecondaryButton>
        </ButtonGroup>
      </Card>
    </SuccessContainer>
  );
}

// Styled Components
const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f6f9fc;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 500px;
  text-align: center;

  h1 {
    font-size: 2rem;
    color: #4caf50;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
`;

const SuccessIcon = styled.div`
  font-size: 3rem;
  color: #4caf50;
  margin-bottom: 15px;
`;

const GiftDetails = styled.div`
  margin-top: 20px;
  text-align: left;
  border-top: 1px solid #eee;
  padding-top: 20px;

  h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;

  span {
    color: #777;
  }
`;

const StatusBadge = styled.span`
  background: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ActionButton = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

const SecondaryButton = styled.button`
  background: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background: #f0f8ff;
  }
`;

export default SuccessPage;
