import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuoteLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import testimonialImage1 from './michael.jpg';
import testimonialImage2 from './emilyj.jpg';
import testimonialImage3 from './sarakpic.jpg';

const testimonials = [
  {
    text: "We love StorkBox around here! What's cool is no month is the same—it's a brand new set of essentials tailored to our baby's needs.",
    author: "Emily Fanver",
    handle: "@emily.fanver",
    image: testimonialImage1
  },
  {
    text: "StorkBox has been a game-changer for us as new parents. The curated items are always high-quality and age-appropriate.",
    author: "Michael Johnson",
    handle: "@mike.johnson",
    image: testimonialImage2
  },
  {
    text: "I appreciate how StorkBox takes the guesswork out of shopping for baby essentials. It's like having a personal baby shopper!",
    author: "Sarah Lee",
    handle: "@sarah.lee",
    image: testimonialImage3
  }
];

function TestimonialSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const nextTestimonial = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <Wrapper>
      <Header>
        <Title>What Parents Are Saying</Title>
        <Subtitle>
          Monthly curated baby essentials tailored to your little one's needs, making parenting easier and more joyful.
        </Subtitle>
      </Header>
      <TestimonialContainer>
        <AnimatePresence custom={direction}>
          <Testimonial
            key={currentIndex}
            custom={direction}
            initial={{ opacity: 0, x: 300 * direction }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 * direction }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <QuoteIcon><FaQuoteLeft /></QuoteIcon>
            <TestimonialImage src={testimonials[currentIndex].image} alt={testimonials[currentIndex].author} />
            <Content>
              <Quote>{testimonials[currentIndex].text}</Quote>
              <Author>{testimonials[currentIndex].author}</Author>
              <Handle>{testimonials[currentIndex].handle}</Handle>
            </Content>
          </Testimonial>
        </AnimatePresence>
        <NavButton onClick={prevTestimonial} left>
          <FaChevronLeft />
        </NavButton>
        <NavButton onClick={nextTestimonial} right>
          <FaChevronRight />
        </NavButton>
      </TestimonialContainer>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(135deg, #fbf8f3 0%, #f5e5d5 100%); /* Neutral Warmth */
  text-align: center;
  overflow: hidden;
`;

const Header = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #33333; /* Soaring Oyan */
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
`;

const TestimonialContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  height: 400px;
`;

const Testimonial = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const QuoteIcon = styled.div`
  font-size: 2rem;
  color: #447c9d; /* Moody Blue */
  margin-bottom: 20px;
`;

const TestimonialImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #c1ddd1; /* Calm Oyan */
  margin-bottom: 20px;
`;

const Content = styled.div`
  text-align: center;
`;

const Quote = styled.p`
  font-size: 1.3rem;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 20px;
  font-style: italic;
  line-height: 1.6;
`;

const Author = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  color: #333333; /* Very Dark Grey */
`;

const Handle = styled.p`
  font-size: 0.9rem;
  color: #9dC4B1; /* Soaring Oyan */
`;

const NavButton = styled.button`
  background: #447c9d; /* Moody Blue */
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  ${props => props.left ? 'left: -25px;' : 'right: -25px;'}

  &:hover {
    background: #9dC4B1; /* Soaring Oyan */
  }
`;

export default TestimonialSection;